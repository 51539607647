#footer {
    background:  var( --color-bg);

    .short-links {
        color: white !important;
        text-decoration: underline;
    }

    .short-links-list {
        color: white;
    }

    .main-wrapper {
        justify-content: space-between;
    }

    ul li {
        margin: 0 10px;
    }

    .footer-navbar li:hover a,
    ul li:hover a i {
        color: white;
        text-decoration: none;
    }
    .footer-end {
        z-index: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .container {
        z-index: 5;
    }
}


    #footer {
        .footer-end {
            display: none;
        }

        .subscribe > div:first-of-type {
            padding: 6px 6px;
            background-color: #d9d9d9;
            color: #48494a;
            font-size: 17px;
            height: 40px;
            border-bottom-left-radius: 7px;
            border-top-left-radius: 7px;
        }
        .subscribe > div:nth-child(2) input {
            padding: 3px 7px;
            font-size: 14px;
            color: black;
            background-color: #d9d9d9;
            border: none;
            height: 40px;
            outline: none;
        }
        .subscribe > div:nth-child(3) a {
            display: inline-block;
            padding: 6px 6px;
            font-size: 17px;
            color: #f5f5f5;
            background-color: var(--color-dark-gray);
            text-decoration: none;
            height: 40px;
            border-bottom-right-radius: 7px;
            border-top-right-radius: 7px;
            transition: all 0.2s;
        }
    }

