
.button-submit{
    background-image: var(--button-theme);
    /* var(--button-theme); */
    font-size:17px;
    width: fit-content;
    transition: .4s;
    
}

@keyframes heartbeat {
    0% {
        transform: scale(1);

    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }

}

.button-submit:hover{

    transition: .4s;
    cursor: pointer;
    animation: heartbeat 2s infinite;


}







.button-submit .inner-layer input{
    color:white!important;
    white-space: nowrap !important;
    text-decoration:none!important;
       border:0px solid white; 
      background-color: transparent !important;
      font-weight: bold;

}

.button-submit .inner-layer i{
    font-size:23px;
    color:white!important;
}




/* For mobile phones: */

@media only screen and (max-width: 768px) {
    
.button-submit .inner-layer input{
    font-size: 1.1rem;
}
            }
        
        
          /* For tablets phones: */
        
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
        
           
.button-submit .inner-layer input{
    font-size: 1.3rem;
}
           
            }
        
        /* For desktop screen sizes  */
        @media only screen and (min-width: 1024px) {
           
        
.button-submit .inner-layer input{
    font-size: 1.6rem;
}
            }