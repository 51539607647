.fa-envelope {
    display: unset;
}

.text-yellow {
    color: #f9e001 !important;
    font-weight: 600 !important;
}

.side-nav {
    .search-field {
        border-radius: 4px 0 0 4px;
        border: none;
    }
    .search-btn {
        background-color: lightgray;
        //set background color to light gray

        border-radius: 0 4px 4px 0;
    }

    .text-black {
        color: black;
        border: none;
    }

    z-index: 100;
    width: 70%;
    position: fixed;
    height: 100%;
    display: none;
    top: 0px;
    left: 0px;
    background-color: var(--color-theme);

    box-shadow: 6px 0px 10px rgba(0, 0, 0, 0.25);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    .body-wrapper {
        height: 92%;

        .side-nav-body {
            gap: 5px;
            padding: 5px;
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }
}

@media screen and (max-width: 770px) {
}
