.yus:hover{
    //change courser to pointer
    cursor: pointer;
    transition: .4s;
 transform: translateY(-10px) scale(1.1);
    color: lightgray !important;

}

.yus{

}


