.services-body {
    position: relative;
    width: 95%;
    margin-left: 2.5%;

    padding-top: 1%;
  
    justify-content: center;

    .heading {
        text-align: center;
      
       
    }

    .contents {
       
        height: auto;
        border: 2px solid grey;
        border-radius: 10px;

        .heading-2 {
            margin-right: 20%;
            margin-left: 20%;
            background: var(--button-theme);
            color: white;
            text-align: center;
            border-radius:10px;

            .h2 {
            }
        }

        .cards-list {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            height: auto;
            position: relative;
            top:0px;
        }
    }
}

/*Mobile Screens*/

@media only screen and (max-width: 768px) {
    .services-body {
        height: max-content;
      

        .contents {
            .heading-2 {

                margin-left: 2%;
                margin-right: 2%;
             

                .h2 {
                    size: 1em;
                }
            }

            .cards-list {
                display: flex;
                overflow-x: hidden;
                flex-direction: column;
            }
        }
    }
}
