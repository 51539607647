
.button-pink{
    background-image: linear-gradient(to right, #C5344E, #C5344E);
   font-size: inherit !important;
}

.button-pink .inner-layer a{
    color:white!important;
    white-space: nowrap !important;
    text-decoration:none!important;
  
}

.button-pink .inner-layer i{
    font-size:inherit !important;
    color:white!important;
}
