.m-comp:hover{
    //change courser to pointer
    transition: .4s;
    color: lightgray !important;
    border-radius: 10px;
    box-shadow: 4px 4px 8px rgba(0,0,0,0.25);
}

.icon {
    color: #f9a401;
}
.h3 {
    color: var(--bg-color);
}

@media only screen and (max-width: 600px) {
}
//create breakpoint for tablet
@media only screen and (min-width: 600px) and (max-width: 1024px) {

    .mission-component {
        width: 30vw !important;
    }


    .hover-overlay:hover{
        transform: scale(1.1) !important;
        .m-comp{
            background: rgba(0,0,0,0.9) !important;

        }
    }

}

//create breakpoint for desktop
@media only screen and (min-width: 1024px) {
    .mission-component {
        width: 30vw !important;
    }

    .hover-overlay:hover{
        transform: scale(1.1) !important;
        .m-comp{
            background: rgba(0,0,0,0.9) !important;

        }
    }
}
