@media only screen and (max-width: 600px) {
    .mission-component-wrapper {
        flex-direction: column;
    }
}
//create breakpoint for tablet
@media only screen and (min-width: 600px) and (max-width: 1024px) {

     .mission-component-wrapper {
        flex-direction: row;
        justify-content: space-around;
    }

}

//create breakpoint for desktop
@media only screen and (min-width: 1024px) {
    .mission-component-wrapper {
        flex-direction: row;
        justify-content: space-around;
    }
}
.img-bg{
  background-image: url("../../assets/img/ba.png");
  background-position: center left;
  background-repeat: no-repeat;
  object-fit: cover;
}
