.scrollIcon{
    bottom: 3%;
    left:1%;
    z-index:10;
    color: black;
    width: 50px;
    height: 50px;
    border-radius: 50%;
     //background-color:var(--color-theme);
    justify-content: center;
    position:relative;
    margin: auto;
    justify-self: center;
    //animation: glow-scrollIcon 4s linear infinite;
   
}
.scrollIcon a{  width: 50px;
    height: 50px;
   margin: auto;
    font-size:2.5em;
   
}
.red-dot{
    position:absolute;
    top:2px;
    right:1px;
    width:10px;
    background-color:red;
    height:10px;
    border-radius:50%;

}
.scrollIcon a i{
    color:lightgray;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top:50%;
}


@keyframes glow-scrollIcon{

    0%{
        box-shadow: 0px 0px 0px 0px  var(--color-theme);
    }

    50%{
        box-shadow: 1px 1px 8px 3px  var(--bg-color);
    }

    100%{
        box-shadow: 0px 0px 0px 0px  var(--color-theme);
    }

}
