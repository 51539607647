.section-line {
    width: 15%;
    height: 4px;
    background-color: var(--color-theme);
}

.line {
    width: 15%;
    height: 2px;
}
