
.manage-photos .card{
    width:45% !important;
}



/* For mobile phones: */

@media only screen and (max-width: 768px) {

.manage-photos .card{
    width:45% !important;
}
            }


          /* For tablets phones: */

        @media only screen and (min-width: 768px) and (max-width: 1024px) {

.manage-photos .card{
    width:45% !important;
}


            }

        /* For desktop screen sizes  */
        @media only screen and (min-width: 1024px) {

.manage-photos .card{
    width:30% !important;
}

            }