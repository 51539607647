.slider-control-wrapper {}

.logo-wrapper {
    position: absolute;
    right: 0;
    bottom: -6%;
    z-index: 9;

}

#home-tracker {
    background-color: var(--color-theme) !important;
}

#home-tracker h1 {
    font-size: 75px !important;



    // position: absolute;
    // top: 15%;
    width: 100%;

    text-shadow: 0 1px 0 hsl(174, 5%, 80%),
        0 2px 0 hsl(174, 5%, 75%),
        0 3px 0 hsl(174, 5%, 70%),
        0 4px 0 hsl(174, 5%, 66%),
        0 5px 0 hsl(174, 5%, 64%),
        0 6px 0 hsl(174, 5%, 62%),
        0 7px 0 hsl(174, 5%, 61%),
        0 8px 0 hsl(174, 5%, 60%),

        0 0 5px rgba(0, 0, 0, .05),
        0 1px 3px rgba(0, 0, 0, .2),
        0 3px 5px rgba(0, 0, 0, .2),
        0 5px 10px rgba(0, 0, 0, .2),
        0 10px 10px rgba(0, 0, 0, .2),
        0 20px 20px rgba(0, 0, 0, .3);

}


.slider-control-dot {
    background-color: gray;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    border: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0 5px;
    opacity: 1;
    transition: all 0.3s ease;
}

.active {
    background-color: white;
    border: none;
    border-radius: 50%;
    margin: 0 5px;
    opacity: 1;
    transition: all 0.3s ease;
}

.inactive {
    background-color: gray;
    border: none;
    border-radius: 50%;
    margin: 0 5px;
    opacity: 1;
    transition: all 0.3s ease;
}

.slider-wrapper {
    height: 100vh;
    overflow: hidden;
}


.title-text {
    background-color: var(--color-theme);
    top: -60px;
    position: relative;
    font-style: normal;
    animation: upanddown 3s linear 0s infinite;
}

.location {

    background-image: url("location.PNG");
    height: 12vh;
    aspect-ratio: 1/1;
}

.ellipse {
    top: -3.8em;
    right: 0em;
    width: 100%;
    height: auto;
    z-index: 9;
}

.order-btn {
    position: absolute;
    bottom: 120px;
    left: 25%;
    transform: translate(-10%, 0);
}

.test {
    position: absolute;
    top: 50%;
    z-index: 15;
}

.home {
    height: 100vh;
    width: 100% !important;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
}

.home-btn {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border: none;
    color: black;
    font-weight: 700;
    font-size: 14px;
    border-radius: var(--border-radius);
}



@keyframes upanddown {
    0% {
        top: -40px;
    }

    50% {
        top: -20px;
    }

    100% {
        top: -40px;
    }
}

/* For mobile phones: */

@media only screen and (max-width: 768px) {
    .title-text {
        width: 75%;
    }

    #home-tracker h1 {
        font-size: 38px !important;

    }

}

/* For tablets phones: */

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .title-text {
        width: 50%;
    }

    #home-tracker h1 {
        font-size: 55px !important;

    }


    .ellipse {
        display: none;
        top: -19em;
        left: 0em;
        width: 100vw;
        height: auto;
        z-index: 9;
    }
}

//For large screens
@media only screen and (min-width: 1024px) {

    .fsn:hover {

        background: black !important;
        transition: ease-in 0.5s;
        transform: scale(1.1);
        color: white !important;

    }

    .fsn {

        transition: ease-in 0.5s;
    }

    .title-text {
        width: 30%;
    }

    .ellipse {
        display: none;
        top: -19em;
        left: 0em;
        width: 100vw;
        height: auto;
        z-index: 9;
    }

    .home {
        overflow-x: hidden;
    }

    .overlay-logo {
        display: none;
    }
}

//!curve
.wave {
    position: absolute;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.wave svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 100px;
}

.wave .shape-fill {
    fill: #FFFFFF;
}

.top-top {
    background: linear-gradient(rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0.2)), url("homebg.jpg") no-repeat center;
    background-color: var(--color-theme);
    background-size: cover;
}