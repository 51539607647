.header {
  background-color: transparent;
  position: fixed;
  z-index: 10;
  height: fit-content;
  top: 0;
}
.header-shadow {
  box-shadow: 6px 0 10px rgba(0, 0, 0, 0.25);
}
.header-logo img{
  width:50px;
  height: auto;
}
.header-logo, .book-now {
  display: none;
}
.hide-shadow {
  box-shadow: none;
}
.header .line {
  width: 90%;
  margin: 5px auto;
  background-color: #ccc;
}
.navBar {
  align-items: flex-start;
  z-index: 10;

  .slogan {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .slogan-texts {
      justify-content: center;
      align-items: center;
      font-style: normal;
      font-weight: bold;
      color: #737272;
    }

    .slogan-logo {
      width: fit-content;
      img {
        width: 40%;
      }
    }
  }
}
.menu-bars {
  font-size: 30px;
}
.w-icon {

  position: fixed;

  bottom: 0;

  left: 2%;

}
.s-icon {

  position: fixed;

  bottom: 1%;

  right: 2%;
  display: none;

}
.logo {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  img {
    transition: all 1s linear;
  }
}
.logo-out {
  width: 15%;
}
.logo-inview {
  width: 35%;
}

#header1{
 
}
.in-viewport{
  background-color: transparent;
}
.out-viewport{
  background-color: var(--color-theme);
  box-shadow: 4px 0px 8px rgba(0,0,0,0.25);
}
@media screen and (max-width: 600px) 
{
   
.header-logo img{
  width:50px;
  height: auto;
}
}
@media screen and (min-width: 600px) and (max-width: 1024px) {
  
.header-logo img{
  width:100px;
  height: auto;
}
  .header-logo img{
    display: none !important;
  }
  .logo-out {
    width: 5%;
    display: none !important;
  }

  .logo-inview {
    width: 15%;
    display: none !important;
  }
}
@media screen and (min-width: 1024px) {
  
  .slogan {
    margin-right: 5rem;
  }
  .logo-out {
    width: 5%;
  }

  .logo-inview {
    width: 15%;
  }
}