.project-item-title {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.4);
  justify-content: center;
  align-items: center;
  display: flex;
}

.item {
  transition: transform 0.5s;
}

.project-item-anchor {
  text-decoration: none;
  position: absolute;
  bottom: 10px;
  left: 50%;
  background: var(--button-theme-dark);
  border-radius: 20px;
  color: #fff;
  display: inline-block;
  width: fit-content;
  padding: 5px 30px;
  transform: translateX(-50%);
  white-space: nowrap;
  transition: all 0.5s;
}

.project-item-anchor:hover {
  color: var(--color-line);
  text-decoration: none;
}

.work-img-height {
  height: 250px !important;
}

#work {
  .react-tabs__tab {
    color: #fff;
    background-color: var(--color-theme);
    margin-right: 30px;
    transition: all 0.4s;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .container-work {
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;

    img {
      width: 100%;
      height: 200px !important;
    }

    @media only screen and (max-width: 768px) {
      img {
        width: 100%;
        height: auto !important;
      }

    }

  }

  .react-tabs__tab--selected,
  .react-tabs__tab:hover {
    background-color: #ccc;
    color: var(--color-line);

    border: none;
  }

  .react-tabs__tab-list {
    border: 0;
  }

}

#work .react-tabs__tab-list {
  display: flex;
  overflow: auto;

  .react-tabs__tab {
    white-space: nowrap;
  }
}


#work .react-tabs__tab-list::-webkit-scrollbar-track {
  background-color: #fff;
}

#work .react-tabs__tab-list::-webkit-scrollbar {
  height: 10px;
  background-color: #fff;
}

#work .react-tabs__tab-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #D9D9D9;

  height: 5px;
}