.side-nav-menu-item {
    width: 100%;
    .title-part {
        border-radius: 5px 5px 0 0;
    }
}

.divider {
    color: white;
    height: 0px;
}

.side-nav-menu-item > div {
    a {
        font-size: 19px !important;
    }

    font-weight: bold;
    i {
        font-size: 22px !important;
    }
}
