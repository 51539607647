@media only screen and (max-width: 600px) {
}
//create breakpoint for tablet
@media only screen and (min-width: 600px) and (max-width: 1024px) {
    .the-form {
        width: 60% !important;
        margin: auto !important;
    }
}

//create breakpoint for desktop
@media only screen and (min-width: 1024px) {
    .the-form {
        width: 45vw !important;
        margin: auto !important;
        align-items: flex-start;
    }
}
