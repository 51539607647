
.nav-link{
    color: var(--bg-color) !important;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 1rem;
    transition: all 0.3s ease-in-out;
    position: relative;
    text-decoration: none;
    cursor: pointer;

}
.d-menu-item{
position:relative;
top:0px;
right:0px;
}
.d-menu-item>span{
    font-size:25px;
}
.d-menu-item .underline{
    width:0;
    transition:width 1s;
    height:5px;
    color: var(--color-accent) !important;
    background-color:var(--yellow-color) !important;;
    border: none;
    text-decoration: none;

}
.d-menu-item:hover .underline{
    width:100%;
}
.d-menu-item .drop-down-list{
    display:none;
    position:absolute;
    opacity:0;
    top:0px;
    right:0;
    z-index:10;
    width:20em;
    height:0px;
    overflow:hidden;
    transition:top 1s;

}

.d-menu-item .drop-down-list li{
    font-size:17px;
}

.d-menu-item:hover .drop-down-list{
    display:block;
    position:absolute;
    top:40px;
    opacity:1;
    height:auto;
    left:-10px;
    z-index:10;
    width:20em;
}