
.error_msg{
    top:30%;
}
.vancacies{
  position: relative;
  top:5em;
}
.error_img{
    top:20%;
    width:100%;

    img{
        width:10%;
        height:auto;
    }
}
@media only screen and (max-width: 768px) {

    .error_img{
      img{
        width:30%;
      }
      
    

    }
  }