.about-wrapper {
  border-left: 5px solid red;

  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);

  position: relative;
  top:0px;
  right:0px;
  transition: all 0.5s;
  
}
.about-wrapper:hover{
  top:-10px;
  right:-10px;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
  border-left: 5px solid red;

}

//.about-us{
//  background-image: url("blob.svg");
//  background-repeat: no-repeat;
//  background-position: right top;
//}

@media only screen and (max-width: 600px) {
  .about-us{
  background-image: url("blob.svg");
  background-repeat: no-repeat;
  background-position: right top;
}
}
//create breakpoint for tablet
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .content-container {
    width: 60% !important;
    margin: auto !important;
  }
}

//create breakpoint for desktop
@media only screen and (min-width: 1024px) {
  .about-wrapper{
    width: 100%;

  }

  .about-wrapper:hover{
    width: 110%;
  }



  .about-text {
    width: 100% !important;
    margin: auto !important;
    font-size: 1.4rem !important;
    font-weight: 600;
  }
}
