.msd-close{
    font-size: 24px;
    position: relative;
    top:-6px;
    left:49%;
    z-index: 1000;
}
.msd-notes{
    font-size: 17px;
    position: absolute;
    top:2px;
    left:2px;
    z-index: 1000;
}
.msg-diaglogue{
    top: 3%;
    width:90%;
    transform: translate(-50%,0%);
    left: 50%;
    z-index: 100;
}



  /* For mobile phones: */

  @media only screen and (max-width: 768px) {
   
.msg-diaglogue{
   
    width:90%;
}
   

}

  /* For tablets phones: */

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    
.msg-diaglogue{
   
    width:80%;
}
   
   
}
//For large screens
@media only screen and (min-width: 1024px) {
   
    
.msg-diaglogue{
   
    width:50%;
}

}
