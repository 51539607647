.active {
    background-color: white;
    border: none;
    border-radius: 50%;
    margin: 0 5px;
    opacity: 1;
    transition: all 0.3s ease;
}

.inactive {
    background-color: gray;
    border: none;
    border-radius: 50%;
    margin: 0 5px;
    opacity: 1;
    transition: all 0.3s ease;
}

.rent-btn:hover {
    background-color: var(--yellow-color) !important;
    color: var(--color-theme) !important;
    transform: scale(1.1);
    transition: .4s;
}

.service-card:hover {

    //create box shadow
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.7);
    transform: scale(1.05);
    background-color: black;
    transition: .4s;
}


//create breakpoint for mobile
@media only screen and (max-width: 600px) {
    .service-component-wrapper {
        flex-direction: column;
    }
}

//create breakpoint for tablet
@media only screen and (min-width: 600px) and (max-width: 1024px) {

    .service-component-wrapper {
        flex-direction: row;
        justify-content: space-around;
    }

    .tt {
        justify-content: center;
    }

}

//create breakpoint for desktop
@media only screen and (min-width: 1024px) {
    .service-component-wrapper {
        flex-direction: row;
        justify-content: space-around;
    }

    .tt {
        justify-content: space-around;
    }

    .slick-prev:before,
    .slick-next:before {
        color: black !important;
        font-size: 50px !important;
        z-index: 100;
    }
}


.slick-dots li button:before {
    font-size: 20px !important;
}

.slick-dots {
    bottom: -37px;

}

//.slick-prev:before, .slick-next:before{
//    color: black !important;
//    font-size: 25px !important;
//}
.slick-prev {
    left: -45px !important;
}

.slick-next {
    right: -15px !important;
}

.clip-out {
    padding: 0 60px !important;
}


@media only screen and (max-width: 1100px) {

    #services .slick-prev:before,
    #services .slick-next:before {
        display: none;

    }

    .clip-out {
        padding: 0 10px !important;
    }
}

//create forwards and backwards animation

@keyframes forwards {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}