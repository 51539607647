.contact-container {
    //border-left: 5px solid var(--color-theme) !important;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    transition: ease-in 0.5s;
}


.button-container {
    .directions-button {
        background-image: var(--button-theme);
        box-shadow: 4px 4px 4px rgba(177, 172, 172, 0.25);
        border-radius: 4px;
    }
}
.heading-span {
    color: var(--color-theme) !important;
}
.mail-link1 {
    color: var(--color-orange) !important;
    text-decoration: underline;
}

@media only screen and (max-width: 600px) {
}
//create breakpoint for tablet
@media only screen and (min-width: 600px) and (max-width: 1024px) {
}

//create breakpoint for desktop
@media only screen and (min-width: 1024px) {
    .contact-container {
        width: 45vw !important;
        margin: auto !important;
    }
}
