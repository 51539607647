
.button-square{
    background-image: linear-gradient(to right, #F9A401, #F95A01);
    font-size:17px;
    width: fit-content;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    
}

.button-square .inner-layer{
    /* border:1px solid white; */
}

.button-square .inner-layer a{
    color:white!important;
    white-space: nowrap !important;
    text-decoration:none!important;
   
}

.button-square .inner-layer i{
    font-size:inherit !important;
    color:white!important;
}
