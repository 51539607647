@import "~react-image-gallery/styles/scss/image-gallery.scss";

* {
    box-sizing: border-box;
}

html {
    --color-theme: #0089C0;
    --color-accent: #2A0060;
    --color-accent-ligt: #580060;
    --color-bg: #2A0060;
    --color-purple: #AF2EBA;
    --vertical-gradient: linear-gradient(180deg, #1F0045 0%, #0E031D 100%);
    --text-brown: #561f00;
    --bg-color: #a03c04;
    --blue-color: #fc2020;
    --bg-color-imge: linear-gradient(90deg, #580060 -7.65%, #2A0060 100%);
    --yellow-color: #F9E001;
    --slider-bg: linear-gradient(180deg, #580060 0%, #2A0060 55.73%, var(--color-theme) 100%);
    ;
    --btn-border: 5px;
    --button-theme: linear-gradient(90deg, #0089C0 2.71%, #148A9A 100%);
    --button-theme-reversed: linear-gradient(90deg, #148A9A 100%, #0089C0 2.71%);
    --button-theme-dark: linear-gradient(90deg, #148A9A 0%, #053C44 100%);
    --dark-gradient: linear-gradient(180deg, #1F0045 0%, #0E031D 100%);
    --color-line: #0089C0;
    --color-text: #fff;
    --image-border-radius: 10px;
    --border-radius: 8px;
    --border-color: #b1acac;
    scroll-behavior: smooth;
    --color-dark-gray: #3F3C3C;
    --color-orange: #F95A01;

    @media only screen and (max-width: 768px) {
        --border-radius: 4px;
    }
}

.bg-theme-gradient-dark {
    background: var(--button-theme-dark) !important;
}

.text-orange {
    color: var(--color-orange) !important;
}

@font-face {
    font-family: PT Mono;
    src: url(PTMono-Regular.ttf);
}

.font-mono {
    font-family: PT Mono !important;

}

@font-face {
    font-family: Roboto;
    src: url(Roboto-Regular.ttf);
}

.font-roboto {
    font-family: Roboto !important;

}

@font-face {
    font-family: oswald;
    src: url(Oswald-Bold.ttf);
}

.font-oswald {
    font-family: oswald;

}

.cover {
    object-fit: cover !important;
    object-position: center !important;
}

.bg-theme-gradient {
    background: var(--button-theme) !important;
}

.bg-theme-gradient-reversed {
    background: var(--button-theme-reversed) !important;
}

.border-dashed {
    //dashed border
    border: dotted 2px white !important;
}

.border-dashed-reverse {
    //dashed border
    border: dotted 2px var(--color-theme) !important;
}

.text-theme {
    color: var(--color-theme);
}

.w-90 {
    width: 90% !important;
}

.w-80 {
    width: 80% !important;
}

.w-40 {
    width: 40% !important;
}

.h-40 {
    height: 40% !important;
}

.h-60 {
    height: 60% !important;
}

.h-40 {
    height: 40% !important;
}

.border-none {
    border: none !important;
}

.h-85 {
    height: 90% !important;
}

.h-15 {
    height: 10% !important;
}

.p-relative {
    position: relative !important;
}

.square {
    aspect-ratio: 1/1 !important;
}


.square-40 {
    aspect-ratio: 1/1 !important;
    background-color: white;
}

.img-square {
    aspect-ratio: 1/1 !important;
}

.h-20 {
    height: 20vh !important;
}

.text-white {
    color: white !important;
}

.overlap-top {
    position: relative !important;
    top: -10px !important;
}

.grad-dark {
    background: var(--vertical-gradient);
}

.text-yellow {
    color: var(--yellow-color) !important;
}

.bg-yellow {
    background-color: var(--yellow-color) !important;
    background: var(--yellow-color) !important;
}

.myIcon {
    font-size: 3rem;
    position: relative;
    top: 0px;
}

.myIcon1 {
    font-size: 3rem;
    position: relative;
    top: 0px;
    animation: UpAndDown 3s linear infinite;
}

.animate-upanddown {
    position: relative;
    top: 0px;
    animation: UpAndDown 3s linear infinite;

}

@keyframes UpAndDown {
    0% {
        top: 0px;
    }

    50% {
        top: -15px;

    }

    100% {
        top: 0px;

    }
}

.bg-p {
    background-color: var(--color-theme);
}

.bg-blue {
    background: #1F0045;
}

.bg-theme {
    background: var(--color-theme) !important;
}

.border-theme {
    border: 2px solid var(--color-theme);
}

.bg-dark-gray {
    background: #3F3C3C;
}

.vl {
    border-left: 1px solid darkgray;
    //height: 600px;
}

.bg-light-gray {
    background: #737272;
}

.bg-extra-light-gray {
    background: #D9D9D9;
}

.bg-btn {
    background: linear-gradient(90deg, #000000 -7.65%, #1F0045 100%);
}

.make-stick {
    min-height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.my-icon {
    font-size: 1.9rem;
    color: black;
    transition: .3s;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-wrap {
    white-space: nowrap;
}

.overflow-hidden {
    overflow-x: scroll !important;
}

.my-icons-container {

    background-color: white;
    border-radius: 50%;
    padding: .2rem;
    margin: .5rem;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s;
}

.my-icons-container:hover {
    background-color: black;
}

.bg-op {
    background-color: rgba(0, 0, 0, 0.3);
}

.bg-purple {
    background-color: var(--color-bg);
}

.bg-white {
    background: white;
}

.text-red {
    color: red;
}

.text-orange {
    color: #F95A01;
}

.semi-bold {
    font-weight: 400 !important;
}

.par-text {
    font-size: 20px !important;
}

.curved {
    border-radius: 30px !important;
}

.curved-1 {
    border-radius: 12px !important;
}

.curved-2 {
    border-radius: 8px !important;
}

.circle {
    border-radius: 50% !important;
}

.text-accent {
    color: var(--color-accent) !important;
}

.bg-accent {
    background-color: var(--color-accent) !important;
}

.text-accent-light {
    color: var(--color-accent-ligt) !important;
}

.text-purple {
    color: var(--color-purple) !important;
}

.text-black {
    color: #0E031D !important;
}

.text-gray {
    color: lightgray !important;
}

.top-opposite {
    top: -100% !important;
}

.text-theme {
    color: var(--color-theme);
}

.left-border {
    border-left: 5px solid var(--color-theme) !important;
    transition: ease-in 0.5s;
}

.left-border:hover {
    border-left: 5px solid var(--color-orange) !important;
    transition: ease-in 0.5s;
}

.brown-text {
    color: var(--bg-color);
}

.title-brown {
    color: #561f00;
}

.top-50 {
    top: 50%;
}

.underline {
    display: block;
    border: 1px solid var(--text-brown);
    width: 90%;
    margin: 10px auto;
    height: 1px;
    animation: underline 1s linear;
}

@keyframes underline {
    from {
        width: 0%;
    }

    to {
        width: 90%;
    }
}

.border-radius {
    border-radius: var(--border-radius);
}

.opacity-5 {
    opacity: 0.5;
}

.opacity-7 {
    opacity: 0.7;
}

.top-40 {
    top: 40%;
}

.left-0 {
    left: 0px;
}

.right-0 {
    right: 0px;
}

.read-more {
    color: var(--color-line);
    text-decoration: underline;
}

.line-slide {
    background-color: #ccc;
}

.hover:hover {
    opacity: 0.5;
}

.hover-zoom:hover {
    transform: scale(1.2);
}

.hover-zoom-1:hover {
    transform: scale(1.06);
    border-radius: 10px !important;
}

.hover-zoom-2:hover {
    transform: scale(1.02);
    border-radius: 10px !important;
}

.z-index-1 {
    z-index: 1;
}

//!hover effects

.hover-shadow :hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.hover-glow :hover {
    transition: .3s;
    box-shadow: 0 0 10px 0 rgba(255, 255, 255, 1) !important;
    //transform: scale(1.1);
}

.hover-scale :hover {
    transform: scale(1.1);
}

.hover-scale-down :hover {
    transform: scale(0.9);
}

//!classes to hide and animate elements on scroll

.hidden {
    opacity: 0;
    //transition: all 1s ease-in-out;
}

.blurry {
    filter: blur(5px);
}

.left-off-screen {
    transform: translate(-50%);
}

.right-off-screen {
    transform: translate(50%);
}

.down-off-screen {
    transform: translate(0, 50%);
}

.up-off-screen {
    transform: translate(0, -50%);
}

.show {
    transform: translate(0%);
    opacity: 1;
    filter: blur(0);
}

.clip-out {
    overflow-x: hidden;
}

.slow-animation {
    transition: 1s ease-in-out !important;
}

.fast-animation {
    transition: 300ms ease-in-out !important;
}

.mid-animation {
    transition: 500ms ease-in-out !important;
}

.delayed-animation {
    transition-delay: 0.2s;
}

.italic {
    font-style: italic;
}

.thick {
    font-weight: 500;
}


.bg-black {
    background-color: black !important;
}

.z-index-1 {
    z-index: 1;
}

.bg-coffin-card {
    background-color: var(--bg-coffin);
    /*border-radius: var(--border-radius);*/
    /*padding: 1rem;*/
    /*margin: 1rem;*/
    /*box-shadow: 0px 0px 4px rgba(0,0,0,0.25);*/
    /*position: relative;*/
    /*top:0px;*/

}

@font-face {
    font-family: PT Mono;
    src: url(PTMono-Regular.ttf);
}

@font-face {
    font-family: Roboto;
    src: url(Roboto-Regular.ttf);
}

.hide {
    display: none;
}



.top-bar {
    position: sticky;
    left: 0px;
    top: 0px;
}

.float-left {
    float: left !important;
}

.bg-color {
    background-color: var(--bg-color);
}

.text-pink {
    color: #C5344E !important;
}

.bg-pink {
    background: #C5344E !important;
}

.text-gray {
    color: #737272;
    ;
}

.text-black {
    color: black;
}

.route-link {
    font-weight: bold;
    font-size: 1rem;
}

.text-left {
    text-align: start;
}

.btn-red {
    background-color: var(--red-color);
    border: var(--red-color);
}

.bottom-0 {
    bottom: 0;
}

.theme-color {
    color: var(--bg-color);
}

.bg-pink {
    background-color: var(--bg-pink) !important;
}

.mr-1 {
    margin-right: 0.5rem;
}

.rectangle-block {}

.mr-0 {
    margin-right: 0;
}

input:focus,
textarea:focus {
    border: var(--input-border) !important;
    box-shadow: 0px 0px 0.2rem 1px var(--bg-pink) !important;
}

.ml-0 {
    margin-left: 0;
}

.box-hover {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    top: 0px;

}

.box-hover:hover {
    box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.3);
    position: relative;
    top: -15px;

}

.container {
    text-align: left;
}

.text-left {
    text-align: left !important;
}

.box-shadow {
    box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.3);
}

.transition-all-1s {
    transition: all 1s;
}

.bg-image-card:hover {
    background-image: var(--bg-color-imge);
}

.app-border {
    border: 1px solid var(--color-line);
    height: 1px !important;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.scale {
    transform: scale(1);
}

.z-index-9 {
    z-index: 9;
}

.hover-scale:hover {
    transform: scale(1.05);
}

.transition-all {
    transition: all 1s;
}

.top-50 {
    top: 50%;
}

.hw-100 {
    height: 100%;
}

.w-90 {
    width: 90% !important;
}

.underline {
    margin: 1% auto;
    display: block;
    height: 1px;
    width: 50%;
    transition: width 1s;
    background-color: var(--color-line);
}

.underline:hover,
h2:hover .underline,
h1:hover .underline,
h3:hover .underline,
p:hover .underline {
    width: 95%;
}

.z-index-10 {
    z-index: 10;
}

.z-index-100 {
    z-index: 100;
}

.color-theme {
    color: var(--color-line);
}

.top-5 {
    top: 8%;
}

@media only screen and (max-width: 768px) {}


.h-100 {
    height: 100% !important;
}

.top-50 {
    top: 50%;
}

.border-radius {
    border-radius: var(--border-radius);
}

.opacity-5 {
    opacity: 0.5;
}

.opacity-7 {
    opacity: 0.7;
}

.top-40 {
    top: 40%;
}

.left-0 {
    left: 0px;
}

.float-right {
    float: right !important;
}

.right-0 {
    right: 0px;
}

h1,
.h1 {
    font-size: 3em;
}

h2,
.h2 {
    font-size: 2.5em;
}

h3,
.h3 {
    font-size: 1.9em;
}

h4,
.h4 {
    font-size: 1.3em;
}

h5,
.h5 {
    font-size: 1.2em;
}

.read-more {
    color: var(--color-line);
    text-decoration: underline;
}

.line-slide {
    background-color: #ccc;
}

.hover {
    opacity: 1;
    transition: all 0.3s;
}

.hover:hover {
    opacity: 0.5;
}

.hover-zoom:hover {

    transform: scale(1.2);

}

.z-index-1 {
    z-index: 1;
}

.bold {
    font-weight: bold;
}

.bg-grey {
    background-color: #ccc;
}

.rounded-top {
    border-radius: 5px 5px 0px 0px;

}


/* For mobile phones: */

@media only screen and (max-width: 768px) {


    .table-header-home {
        flex-direction: column;
    }

    .p-hide {
        display: none;
    }

    .hover-zoom:hover {

        transform: scale(1.1);

    }

    h1 {
        font-size: 3em;

    }

    h2 {
        font-size: 2em;
    }

    h3 {
        font-size: 1.4em;
    }

    h4 {
        font-size: 1.2em;
    }

    .p-hide {
        display: none !important;
    }

    p,
    ol,
    ul,
    abbr,
    acronym,
    address,
    input,
    textarea {
        font-size: 14px;
    }

    div {
        font-size: 15px;
    }

}


/* For tablets phones: */

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .w-md-50 {
        width: 50% !important;
    }


    .smaller-card-2 {
        width: 40vw !important;
    }


    .table-header-home {
        flex-direction: row;
    }

    .p-hide {
        display: none;
    }

    h1 {
        font-size: 3em;
    }

    h2 {
        font-size: 2.4em;
    }

    h3 {
        font-size: 1.7em;
    }

    h4 {
        font-size: 1.3em;
    }


    p,
    span,
    div,
    ol,
    ul,
    li,
    a,
    abbr,
    acronym,
    address,
    input,
    textarea {
        font-size: 14px;
    }

}

@media only screen and (min-width: 1024px) {

    .home-text {
        font-size: x-large !important;
    }

    .smaller-card {
        width: 20% !important;
    }

    .smaller-card-2 {
        width: 30% !important;
    }

    .d-hide {
        display: none !important;
    }

    .home-dx {
        display: flex !important;
        flex-direction: row !important;
    }

    .home-dy {
        display: flex !important;
        flex-direction: column !important;
        justify-content: space-around;
    }

    .center {
        justify-content: center !important;
        align-items: center !important;
    }

    .span-h {
        height: 100% !important;
    }

    .span-w {
        width: 100% !important;
    }

}






@media only screen and (max-width: 600px) {}

@media only screen and (min-width: 600px) and (max-width: 1024px) {}

@media only screen and (min-width: 1024px) {

    .to-start {
        text-align: start !important;
        margin-left: unset !important;
        margin-right: unset !important;
    }

}

@media only screen and (min-width: 1024px) {
    .card-width-33 {
        width: 33% !important;
    }

    .card-18 {
        width: 18% !important;
    }

    .card-20 {
        width: 20% !important;
    }

    .card-25 {
        width: 25% !important;
    }

    .card-30 {
        width: 30% !important;
    }

    .card-40 {
        width: 40% !important;
    }

    .card-50 {
        width: 50% !important;
    }

    .card-45 {
        width: 45% !important;
    }

    .go-flex {
        display: flex;
        justify-content: space-around;
        flex-direction: row;
    }

    .img-50 {
        width: 50% !important;
    }


    .img-60 {
        width: 60% !important;
    }

    .img-70 {
        width: 70% !important;
    }

    .img-80 {
        width: 80% !important;
    }

    .height-vp {
        height: 100vh !important;
    }

    .height-vp-50 {
        height: 50vh !important;
    }

    .height-vp-60 {
        height: 60vh !important;
    }

    .height-vp-70 {
        height: 70vh !important;
    }

    .height-vp-75 {
        height: 75vh !important;
    }



    .cover {
        object-fit: cover;
    }

    pic-center {
        object-position: center center
    }




}

#root {
    max-width: 5600px !important;
    margin: 0 auto !important;

}