.body-container {
    position: relative;
    top: 100vh;
    left: 0px;

    width: 95% !important;
}

.section-gap {
    height: 10vh;
}
.our-clients,
.body-container {
    width: 100% !important;
}

@media only screen and (max-width: 600px) {
    .group-1 {
        width: 100% !important;
        margin: auto !important;
        flex-direction: column;
    }
}
//create breakpoint for tablet
@media only screen and (min-width: 600px) and (max-width: 1024px) {
    .group-1 {
        width: 100% !important;
        margin: auto !important;
        flex-direction: column;
    }
}

//create breakpoint for desktop
@media only screen and (min-width: 1024px) {
    .group-1 {
        width: 100% !important;
        margin: auto !important;
        flex-direction: row;
        align-items: start;
    }
}
